import { Box, Button, Chip, CircularProgress, Divider, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, Tab, Tabs, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react"
import Layout from "../components/shared-layout";
import Breadcrumb from "../components/shared-breadcrumb";
import useStores from "../hooks/use-stores"
import VmButton from "../components/shared-button";
import VmModal from "../components/shared-modal";
import VmTable from "../components/shared-table";
import TabPanel from "../components/shared-tab-panel";
import { GetCollegeId, PAGE_SIZE, ProdEnvCheck } from "../constants/options";
import ContentLayout from "../components/shared-content-layout";
import { t } from "i18next";
import { TD_FIRST, TD_LAST, TD_NORMAL, THEME_COLOR } from "../constants/style";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { constructImgUrl } from "../constants/settings";
import { DateUtils } from "../utilities/date";
function TabProps(index: number, isActive: boolean) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index} `,
    className: `${isActive ? 'text-red-500' : 'text-black'}`
  };
}

const VisitPage = observer(() => {
  const { visitStore } = useStores();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentVisit, setCurrentVisit] = useState<any>();
  const [startDate, setStartDate] = useState<any>(new Date(
    new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState<any>(new Date().toISOString().split('T')[0]);
  const [tabValue, setTabValue] = useState<number>(0);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    onToggleVisitList();
  }, []);

  const handleShowModal = (id: number) => {
    let target = visitStore.visitListInDateRange.data.find(v => v.id == id);
    setCurrentVisit(target);
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentVisit(null);
    setTabValue(0);
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onToggleVisitList = () => {
    setPage(1);
    visitStore.getTeacherVisitListByDateRange({
      take: PAGE_SIZE,
      skip: 0,
      orgId: 3,
      startDate,
      endDate,
    });
  }

  return (
    <ContentLayout
      pageName={t('VISIT_HISTORY')}
      pageHeading={t('VISIT_HISTORY')}
      breadCrumb={[
        // { label: t('VISIT'), to: "" },
        // { label: t('VISIT_HISTORY'), to: "/visit" }
      ]}
      showHeadingButton={false}
    >
      <div className="grid grid-cols-5 gap-4">
        <TextField
          value={startDate}
          label={t('START_DATE')}
          type="datetime-local"
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2, background: 'white' }}
          className="col-span-2"
          onChange={(value) => setStartDate(value.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={endDate}
          label={t('END_DATE')}
          type="datetime-local"
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2, background: 'white' }}
          className="col-span-2"
          onChange={(value) => setEndDate(value.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          variant="outlined"
          sx={{ marginBottom: 2 }}
          disabled={new Date(startDate) > new Date(endDate)}
          onClick={() => onToggleVisitList()}
        >
          {t('APPLY')}
        </Button>
      </div>

      <VmTable
        loading={visitStore.loading}
        page={page}
        paginationCount={visitStore.visitListInDateRange.data.length == 0 ? 1 : visitStore.visitListInDateRange.data.length / PAGE_SIZE}
        onChangePagination={(event, pageNumber) => {
          setPage(pageNumber);
          visitStore.getOrgVisitListByDateRange({
            take: PAGE_SIZE,
            skip: (pageNumber - 1) * PAGE_SIZE,
            orgId: 3,
            startDate,
            endDate,
          });
          setPage(pageNumber);
        }}
        thead={["ID", t('VISIT_NAME'), t('START_TIME'), t('END_TIME'), "Trainee"]}
        tbody={visitStore.visitListInDateRange.data &&
          visitStore.visitListInDateRange.data.length > 0 ?
          visitStore.visitListInDateRange.data.map((visit: any, index: number) => (
            <>
              <Box marginY={1} />
              <tr key={`visit_list_${index}`}>
                <td className={TD_FIRST}>{index + 1}</td>
                <td className={TD_NORMAL}>{visit.name}</td>
                <td className={TD_NORMAL}>{DateUtils.onConvertDateFormat(visit.startTime.split('T')[0]) + " " + visit.startTime.split('T')[1]}</td>
                <td className={TD_NORMAL}>{DateUtils.onConvertDateFormat(visit.endTime.split('T')[0]) + " " + visit.endTime.split('T')[1]}</td>
                <td className={TD_LAST}>{visit.studentName}</td>
                {/* <td className={TD_NORMAL}>
                  {visit.organisationVisitStudents.length > 0 ?
                    `${visit.organisationVisitStudents[0].result ?
                      visit.organisationVisitStudents[0].result : 0}/100` : `No Marked`}
                </td> */}
               
              </tr>
              <Box marginY={1} />
            </>
          )) : <tr><td colSpan={7} className="text-center py-2">{t('NO_HISTORY_FOUND')}</td></tr>}
      />
      <VmModal
        openModal={showModal}
        onClose={handleCloseModal}
        width={500}
        title={t('VISIT_INFO')}
        showButton={false}
      >
        {currentVisit &&
          <>
            {/* <Box display="flex" justifyContent="center" width="100%" marginBottom={2}>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                sx={{ marginTop: -4, textAlign: 'center', justifyContent: 'center' }}
                TabIndicatorProps={{ style: { background: THEME_COLOR } }}
              >
                <Tab label={t('VISIT_INFO')} {...TabProps(0, 0 === tabValue)} />
                <Tab label={t('UPLOADED_FILES')} {...TabProps(1, 1 === tabValue)} />
              </Tabs>
            </Box> */}
            <TabPanel value={tabValue} index={0} paddingValue={0}>
              <table style={{ width: '100%' }}>
                <thead><th /><th /></thead>
                <tbody>
                  <tr>
                    <td className="font-bold">{t('ORGANISATION')}: </td>
                    <td className="text-right">{currentVisit.name}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">{t('STUDENT_SINGLE')}: </td>
                    <td className="text-right">
                      {currentVisit.organisationVisitStudents.map((ovs: any) => (
                        <Typography>{ovs.studentName}</Typography>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold">{t('CREATE_TIME')}: </td>
                    <td className="text-right">{currentVisit.createTime.split('T')[0] + " " + currentVisit.createTime.split('T')[1]}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">{t('UPDATED_TIME')}: </td>
                    <td className="text-right">{currentVisit.updateTime.split('T')[0] + " " + currentVisit.updateTime.split('T')[1]}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">{t('START_TIME')}: </td>
                    <td className="text-right">{currentVisit.startTime.split('T')[0] + " " + currentVisit.startTime.split('T')[1]}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">{t('END_TIME')}: </td>
                    <td className="text-right">{currentVisit.endTime.split('T')[0] + " " + currentVisit.endTime.split('T')[1]}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">{t('STAFF_NAME')}: </td>
                    <td className="text-right">{currentVisit.staffName}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">{t('DESCRIPTION')}: </td>
                    <td className="text-right">{currentVisit.description}</td>
                  </tr>
                </tbody>
              </table>
              {/* <Typography color={THEME_COLOR} fontWeight="bold">
                {currentVisit.organisationVisitStudents.length > 0 ?
                  `${t('MARK')}: ${currentVisit.organisationVisitStudents[0].result ?
                    currentVisit.organisationVisitStudents[0].result : 0}/100` : t('NO_MARKED')}
              </Typography> */}

              <div className="px-2 mt-2">
                <TextField
                  value={currentVisit.organisationVisitStudents.length > 0 ? currentVisit.organisationVisitStudents[0].remark ?
                    currentVisit.organisationVisitStudents[0].remark : t('NO_FEEDBACK_YET') : t('NO_FEEDBACK_YET')}
                  rows={3}
                  sx={{ width: '100%' }}
                  disabled
                  multiline
                />
              </div>
            </TabPanel>
            {/* <TabPanel value={tabValue} index={1} paddingValue={0}>
              {currentVisit.organisationVisitStudents.length > 0 &&
                currentVisit.organisationVisitStudents[0].organisationVisitDocuments.length > 0 ?
                <div className="grid grid-cols-5 gap-4">
                  {currentVisit.organisationVisitStudents[0].organisationVisitDocuments.map((doc: any) => (
                    <button className="border hover:border-theme hover-border-2"
                      onClick={() => {
                        if (doc.documentName.split('.')[1] !== "pdf") {
                          window.open(constructImgUrl(currentVisit.organisationVisitStudents[0].id, doc.documentName));
                        } else {
                          userStore.getUploadedFile(currentVisit.organisationVisitStudents[0].id, doc.documentName)
                            .then((res) => {
                              const file = new Blob([res], { type: "application/pdf" });
                              const fileURL = URL.createObjectURL(file);
                              window.open(fileURL);
                            })
                        }
                      }}>
                      {doc.documentName.split('.')[1] !== "pdf" ?
                        <img src={constructImgUrl(currentVisit.organisationVisitStudents[0].id, doc.documentName)} />
                        : userStore.loadingFile ? <CircularProgress className="text-theme" />
                          : <>
                            <FilePresentIcon fontSize={"large"} />
                            <Typography variant="caption">PDF</Typography>
                          </>}

                    </button>
                  ))}
                </div>
                : <Typography textAlign="center">{t('NO_DOCUMENT_UPLOADED_IN_THIS_VISIT')}</Typography>}
            </TabPanel> */}
          </>}
      </VmModal>
    </ContentLayout>
  )
});

export default VisitPage;
